import { fetchAllAttributeTypes, updateAttributeType } from '@/api/product'
import { useTableOptions } from '@/composables'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useUsersList() {
  const attributeTypeListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'KEY', value: 'key' },
    { text: 'NAME', value: 'name', sortable: false },
    { text: 'STATUS', value: 'status', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const loadAttributeTypes = () =>
    fetchAllAttributeTypes(
      useTableOptions(options.value, {
        search: searchQuery.value,

        // root_only: 1,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        attributeTypeListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const updateAllAttributeTypes = async (updates = {}) => {
    loading.value = true
    for (const attributeType of selectedRows.value) {
      const data = { ...updates }
      data.name = attributeType.name
      await updateAttributeType(attributeType.id, data)
    }
    loadAttributeTypes()
    loading.value = false
    selectedRows.value = []
  }

  const cachable = [options, searchQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      loadAttributeTypes()
      cache()
    }, 300),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    attributeTypeListTable,
    tableColumns,
    searchQuery,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadAttributeTypes,
    updateAllAttributeTypes,
  }
}
